import React from 'react';
import PropTypes from 'prop-types';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import { useTranslation } from 'next-i18next';

import { convertToZurichTz, formatDate, getDateZurich } from '@utils/date';

import RemovablePart from '@common/components/Voucher/RemovablePart';
import { DotsRow } from '@common/components/Voucher/Voucher.styled';

import Card from './components/Card';
import { CouponWrapper, CardWrapper } from './Coupon.styled';

const Coupon = ({
  templateId,
  isAnimationPlaying,
  setIsAnimationPlaying,
  image,
  slug,
  description,
  title,
  type,
  validUntil,
  dateUsed,
  created,
  onGiftOpen,
  isOpened,
  showRemovablePart,
  onInvalidModalOpen,
}) => {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const createdSOD = convertToZurichTz(startOfDay(new Date(created)));
  const validUntilEOD = validUntil
    ? convertToZurichTz(endOfDay(new Date(validUntil)))
    : null;
  const todayEOD = endOfDay(getDateZurich());

  const daysLeft = differenceInDays(validUntilEOD, todayEOD) + 1;
  const totalDays = differenceInDays(validUntilEOD, createdSOD) + 1;

  const validUntilFormatted = formatDate(validUntilEOD, currentLanguage);

  const isExpired = todayEOD > validUntilEOD;
  const bottomTitle = t('validUntil', 'Gültig bis {{date}}', {
    date: validUntilFormatted,
  });

  return (
    <CouponWrapper>
      <CardWrapper
        data-testid={isExpired || !!dateUsed ? 'invalidCoupon' : 'validCoupon'}
      >
        <Card
          isExpired={isExpired}
          validUntilEOD={validUntilEOD}
          templateId={templateId}
          isAnimationPlaying={isAnimationPlaying}
          setIsAnimationPlaying={setIsAnimationPlaying}
          image={image}
          slug={slug}
          description={description}
          title={title}
          onGiftOpen={onGiftOpen}
          isOpened={isOpened}
          dateUsed={dateUsed}
          onInvalidModalOpen={onInvalidModalOpen}
        />
        <DotsRow />
      </CardWrapper>

      {showRemovablePart && (
        <RemovablePart
          type={type}
          title={bottomTitle}
          daysLeft={daysLeft}
          totalDays={totalDays}
          isValid={!isExpired && !dateUsed}
        />
      )}
    </CouponWrapper>
  );
};

Coupon.propTypes = {
  created: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validUntil: PropTypes.string,
  dateUsed: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  isAnimationPlaying: PropTypes.bool,
  setIsAnimationPlaying: PropTypes.func,
  isOpened: PropTypes.bool.isRequired,
  showRemovablePart: PropTypes.bool,
  onGiftOpen: PropTypes.func.isRequired,
  onInvalidModalOpen: PropTypes.func,
};

Coupon.defaultProps = {
  validUntil: undefined,
  dateUsed: undefined,
  isAnimationPlaying: undefined,
  setIsAnimationPlaying: undefined,
  image: undefined,
  onInvalidModalOpen: null,
  description: '',
  showRemovablePart: true,
};

export default Coupon;
