import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';

export const Backdrop = styled.button`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: rgba(0, 0, 0, 0.75);
  color: ${getThemeColor('grey.100')};
  cursor: pointer;
`;

export const BackdropText = styled(Typography).attrs({ variant: 'paragraph1' })`
  margin: 0;
  padding: 2.5rem 2rem 3rem;
`;
