import styled from 'styled-components';

import { getThemeColor, getThemeTransition, media } from '@utils/styled';

import { COUPON_GIFT_IMAGE } from '@common/images';
import Typography from '@common/components/Typography';

export const UnpackedCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100% - 1px);
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 1rem;
    left: 0;
    z-index: 2;
    display: block;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 43%,
      rgb(0, 0, 0) 100%
    );
  }
`;

export const UnpackedCardContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  opacity: ${({ isAnimationPlaying }) => (isAnimationPlaying ? 0 : 1)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 2.5rem 2rem 3.5rem;
  transition: ${getThemeTransition('opacity', 'slow')};

  ${media.from800up`
    padding: 2.5rem 2.5rem 3.5rem;
    max-width: 100%;
  `}

  @supports not (aspect-ratio: 0.9) {
    &::before {
      content: '';
      display: block;
      padding-top: 75%;
    }
  }
`;

export const UnpackedCardMessage = styled(Typography).attrs({ variant: 'h4' })`
  margin-bottom: 1.5rem;
  color: ${getThemeColor('grey.100')};
  text-align: center;
`;

export const AnimationVideo = styled.video`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-image: ${`url(${COUPON_GIFT_IMAGE})`};
  background-position: center center;
  background-size: cover;
  object-fit: cover;
`;
