import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import {
  WrapperSmaller as BaseWrapperSmaller,
  RemovablePartWrapper as BaseRemovablePartWrapper,
} from '../RemovablePart.styled';

export const WrapperSmaller = styled(BaseWrapperSmaller)`
  display: none;
  border-radius: 0;
  background-color: ${getThemeColor('grey.100')};
  user-select: none;

  ${media.from800up`
    display: block;
  `}
`;

export const RemovablePartWrapper = styled(BaseRemovablePartWrapper)`
  visibility: hidden;
`;
