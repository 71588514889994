import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openGoodie } from '@thunks/goodies';
import { fetchProductsByColorIds } from '@thunks/products';

import Coupon from '@common/components/Voucher/Coupon';

export function Goodie({
  id,
  created,
  dateUsed,
  validUntil,
  template,
  type,
  isOpened,
  showRemovablePart,
  onInvalidModalOpen,
}) {
  const dispatch = useDispatch();
  const { productColorIds = [] } = template;

  const onGiftOpen = useCallback(() => {
    dispatch(openGoodie(id));
  }, [id, dispatch]);

  const onModalOpen = useCallback(() => {
    dispatch(fetchProductsByColorIds(productColorIds));
  }, [productColorIds, dispatch]);

  return (
    <Coupon
      created={created}
      description={template.description}
      image={template.images[0]}
      isOpened={isOpened}
      key={id}
      onGiftOpen={onGiftOpen}
      onModalOpen={onModalOpen}
      dateUsed={dateUsed}
      onInvalidModalOpen={onInvalidModalOpen}
      slug={template.slug}
      templateId={template.id}
      title={template.title}
      type={type}
      validUntil={validUntil}
      showRemovablePart={showRemovablePart}
    />
  );
}

Goodie.propTypes = {
  id: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  isOpened: PropTypes.bool,
  onInvalidModalOpen: PropTypes.func,
  dateUsed: PropTypes.string,
  template: PropTypes.shape({
    conditions: PropTypes.string,
    description: PropTypes.string,
    descriptionDetail: PropTypes.string,
    id: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    productColorIds: PropTypes.arrayOf(PropTypes.string),
    slug: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  validUntil: PropTypes.string,
  showRemovablePart: PropTypes.bool,
};

Goodie.defaultProps = {
  description: undefined,
  images: [],
  isOpened: false,
  dateUsed: undefined,
  validUntil: undefined,
  showRemovablePart: true,
  onInvalidModalOpen: null,
};

export default Goodie;
