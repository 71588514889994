import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import { COUPON_VIP_BG_PATTERN } from '@common/images';

export const CouponWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: ${getThemeColor('grey.0')};
  background-image: url(${COUPON_VIP_BG_PATTERN});
  background-position: 50% 50%;
`;

export const CardWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  /* stylelint-disable-next-line */
  aspect-ratio: 0.85;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-position: 50% 50%;
`;

export const SeeMoreCouponsWrapper = styled(CouponWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getThemeColor('bluegrey.92')};
  background-image: none;
  background-position: unset;
  cursor: pointer;
`;
