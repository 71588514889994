import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import endOfDay from 'date-fns/endOfDay';

import { convertToZurichTz, formatDate } from '@utils/date';

import { GOODIE_EXPIRED, GOODIE_REDEEMED } from '@common/constants/vip';
import UnpackedCard from '../UnpackedCard';
import ActiveCard from '../ActiveCard';
import ExpiredCard from '../ExpiredCard';

const Card = ({
  isOpened,
  isExpired,
  onGiftOpen,
  image,
  title,
  description,
  dateUsed,
  validUntilEOD,
  onInvalidModalOpen,
  templateId,
  slug,
}) => {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const dateUsedEOD = convertToZurichTz(endOfDay(new Date(dateUsed)));

  if (isExpired || !!dateUsed) {
    let dateFormatted = formatDate(validUntilEOD, currentLanguage);
    let modalMessage = GOODIE_EXPIRED;
    let message = t('expiresOn', 'Abgelaufen am {{date}}', {
      date: dateFormatted,
    });

    if (dateUsed) {
      dateFormatted = formatDate(dateUsedEOD, currentLanguage);
      modalMessage = GOODIE_REDEEMED;
      message = t('redeemedOn', 'Eingelöst am {{date}}', {
        date: dateFormatted,
      });
    }

    return (
      <ExpiredCard
        image={image}
        title={title}
        description={description}
        message={message}
        onInvalidModalOpen={onInvalidModalOpen}
        modalMessage={modalMessage}
      />
    );
  }

  if (!isOpened) {
    return (
      <UnpackedCard
        onGiftOpen={onGiftOpen}
        isAnimationPlaying={isAnimationPlaying}
        setIsAnimationPlaying={setIsAnimationPlaying}
      />
    );
  }

  return (
    <ActiveCard
      image={image}
      title={title}
      description={description}
      templateId={templateId}
      slug={slug}
      isAnimationPlaying={isAnimationPlaying}
      setIsAnimationPlaying={setIsAnimationPlaying}
    />
  );
};

Card.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  onGiftOpen: PropTypes.func.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  dateUsed: PropTypes.string,
  validUntilEOD: PropTypes.instanceOf(Date).isRequired,
  onInvalidModalOpen: PropTypes.func,
  templateId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

Card.defaultProps = {
  onInvalidModalOpen: null,
  image: undefined,
  dateUsed: null,
  description: '',
};

export default Card;
