import React from 'react';
import PropTypes from 'prop-types';

import ActiveCard from '../ActiveCard';

import { Backdrop, BackdropText } from './ExpiredCard.styled';

const ExpiredCard = props => {
  const { message, onInvalidModalOpen, modalMessage } = props;

  return (
    <React.Fragment>
      <ActiveCard {...props} isInvalid />
      <Backdrop onClick={() => onInvalidModalOpen(modalMessage)}>
        <BackdropText>{message}</BackdropText>
      </Backdrop>
    </React.Fragment>
  );
};

ExpiredCard.propTypes = {
  message: PropTypes.string.isRequired,
  modalMessage: PropTypes.string.isRequired,
  onInvalidModalOpen: PropTypes.func.isRequired,
};

export default ExpiredCard;
